import { App } from "vue";
import { createI18n, LocaleMessages } from "vue-i18n";

// Define un tipo para las claves de tus mensajes (puede ser `string` o algo más específico)
type MessageSchema = {
  welcome: string;
  hello: string;
};

function loadLocaleMessages(): Record<string, Record<string, string>> {
  const locales = require.context("../i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: Record<string, Record<string, string>> = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default as Record<string, string>;
    }
  });

  return messages;
}
export const i18n = createI18n({
  legacy: false,
  locale: navigator.language.includes("en") ? "en" : "es",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "es",
  messages: loadLocaleMessages(),
  datetimeFormats: {
    es: {
      date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      dateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
      dateTimeNoSecs: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
    },
  },
  numberFormats: {
    en: {
      default: {
        style: "decimal",
        maximumFractionDigits: 20,
      },
      fixed: {
        style: "decimal",
        maximumFractionDigits: 2,
      },
      twoDecimals: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    es: {
      default: {
        style: "decimal",
        maximumFractionDigits: 20,
        useGrouping: true,
      },
      fixed: {
        style: "decimal",
        maximumFractionDigits: 2,
        useGrouping: true,
      },
      twoDecimals: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      fourDecimals: {
        style: "decimal",
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      },
      rounded: {
        style: "decimal",
        maximumFractionDigits: 0,
        useGrouping: true,
      },
    },
  },
});
export default (app: App): void => {
  app.use(i18n);
};
